<template>
    <section class="weChar" :class='_TEMPLATE'>
        <Header v-if="_TEMPLATE==='template-3'" :mainTitle="header.mainTitle" />
        <header v-if="_TEMPLATE==='template-1'  || _TEMPLATE==='template-2'">
            <mt-header :title="getWord(['social_app2','online_service2'])" style="height:0.88rem;font-size: 0.36rem;">
                <router-link to="/customer" slot="left">
                    <mt-button icon="back"></mt-button>
                </router-link>
            </mt-header>
        </header>
        <ul class="wrap">
            <li class="item" v-for="item in list" :key="item.account">
                <div class="left">
                    <img :src="item.img" alt class="img">
                    <div class="txtBox">
                        <span>{{item.name}}</span>
                        <span>{{item.account}}</span>
                    </div>
                </div>
                <div class="right">
                    <button v-if="_TEMPLATE==='template-1'||_TEMPLATE==='template-2'" class="btn" @click="toNext(item.account, item.url)">{{ getWord('online_service3') }}</button>
                    <button v-if="_TEMPLATE==='template-3'" class="btn copy" :key="item.account" :data-clipboard-text="item.account" @click="copyText()">{{ getWord('copy3') }}</button>
                </div>
            </li>
        </ul>
        <p class="tip" v-if="_TEMPLATE!=='template-3'">{{ getWord(['online_service3_tips','social_app2','chat','interface']) }}</p>
    </section>
</template>
<script type="text/javascript">
import { cloudImageBaseUrl } from '@/CONF.js'
import { getCustomerList } from "api/customerList";
import Clipboard from "clipboard";
export default {
    name: "weChar",
    components: {
        Header: () => import('components/base/Header'),

        'mt-header': app.Mint.Header,
        'mt-button': app.Mint.Button,
    },
    data() {
        return {
            cloudImageBaseUrl,
            list: [],
            header: {
                mainTitle: getWord(['social_app2','online_service2']),
            },
        };
    },
    mounted() {
        this.getInfo();
    },
    methods: {
        toNext(account, url) {
            this.$router.push({ path: "/views/my/customerList/wechat", query: { account: account, url: url } });
        },
        getInfo() {
            getCustomerList('3').then(result => {
                if (result.data.code == "SUCCESS") {
                    this.list = result.data.result;
                } else {
                    app.Mint.Toast(result.data.msg);
                }
            })
        },
        async copyText() {
            let clipboard = new Clipboard(".copy");
            clipboard.on("success", function(e) {
                if (clipboard) {
                    clipboard.destroy();
                }
            });
            app.Mint.Toast(getWord('clipboard_done'));
        },
    }
};
</script>

<style type="text/css" scoped lang='scss'>
.weChar {
    background: #f2f2f2;
    height: 100%;

    &.template-3 {

        ul {

            li {

                .left {

                    .txtBox {
                        font-weight: normal;
                    }
                }

                .right {

                    .btn {
                        background: transparent;
                        border: 1px solid #EC2829;
                        color: #EC2829;
                        font-size: .28rem;
                    }
                }
            }
        }
    }

    ul {
        padding: 3%;

        .item {
            display: flex;
            background: #fff;
            height: 1.32rem;
            border-radius: 5px;
            padding: 0 3%;
            box-sizing: border-box;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            .left {
                display: flex;

                .img {
                    width: 1.08rem;
                    height: 1.08rem;
                    margin-right: 0.58rem;
                }

                .txtBox {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    font-size: 0.3rem;
                    font-weight: bold;
                }
            }

            .right {

                .btn {
                    font-size: 0.34rem;
                    color: #fff;
                    background: #4796fc;
                    outline: none;
                    border: 0;
                    height: 0.64rem;
                    border-radius: 3px;
                    line-height: 0.64rem;
                    padding: 0 5px;
                }
            }
        }
    }

    .tip {
        font-size: 0.32rem;
        text-align: center;
        color: #666;
    }
}
</style>